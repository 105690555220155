import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

import favicon from "../../static/favicon.ico";

const SiteMetadata = ({
  pathname,
  locale,
  productTitle,
  productDescription,
}) => {
  const {
    site: {
      siteMetadata: { siteUrl, title, description },
    },
  } = useStaticQuery(graphql`
    query SiteMetadata {
      site {
        siteMetadata {
          siteUrl
          title
          description
        }
      }
    }
  `);

  return (
    <Helmet
      defer={false}
      defaultTitle={productTitle ? productTitle : title}
      titleTemplate={`%s | ${title}`}
    >
      <html lang={locale} />
      <link rel="canonical" href={`${siteUrl}${pathname}`} />
      <meta name="docsearch:version" content="2.0" />
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
      />

      <meta property="og:url" content={siteUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content={locale} />
      <meta property="og:site_name" content={title} />
      <meta
        property="og:description"
        content={productDescription ? productDescription : description}
      />
      <meta property="og:image" content={`${siteUrl}${favicon}`} />
      <meta property="og:image:width" content="512" />
      <meta property="og:image:height" content="512" />
    </Helmet>
  );
};

export default SiteMetadata;
